import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import dataReducer from "./data/dataReducer";

export const ACTIONS = {
  CHECK_DATA_REQUEST: "CHECK_DATA_REQUEST",
  CHECK_DATA_SUCCESS: "CHECK_DATA_SUCCESS",
  CHECK_DATA_FAILED: "CHECK_DATA_FAILED"
}

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  data: dataReducer,
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;
