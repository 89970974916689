import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, refreshAccount } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

// const truncate = (input, len) =>
//   input.length > len ? `${input.substring(0, len)}...` : input;

const truncateWallet = (input, len) => {
  const inputLenght = input.length;
  return input.length > len ? `${input.substring(0, len-7)}...${input.substring(inputLenght-4, inputLenght)}` : input;
}

const weiToEth = (weiValue) => weiValue/1000000000000000000;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 15px;
  color: var(--accent-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 34em;
  @media (max-width: 720px) {
    width: 42em;
  }
  @media (min-width: 1352px) {
    width: 35em;
  }
  @media (min-width: 1792px) {
    width: 36em;
  }
`;
  // @media (min-width: 767px) {
  //   flex-direction: row;
  // }

export const StyledLogo = styled.img`
  width: 36em;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  width: 5em;
  height: 5em;
  transition: width 0.5s;
`;
// border: 4px dashed var(--secondary);
// background-color: var(--accent);
// @media (min-width: 900px) {
//   width: 250px;
// }
// @media (min-width: 1000px) {
//   width: 300px;
// }

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain); // get the redux store
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Select your quantity and click mint.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: ""
  });

  const cost = data.cost;
  const totalCostWei = String(cost * mintAmount);
  const maxMintAmount = blockchain.maxMintAmount;
  const balance = blockchain.balance;

  // console.log(`cost: ${cost} - maxMintAmount: ${maxMintAmount} - balance: ${balance}`);
  const claimNFTs = () => {
    const gasLimit = CONFIG.GAS_LIMIT;
    const totalGasLimit = String(gasLimit * mintAmount);
    // console.log("Cost: ", totalCostWei);
    // console.log("Gas limit: ", totalGasLimit);
    // setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setFeedback('Please wait');
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        // console.log(err);
        setFeedback("Sorry, something went wrong.\nPlease try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback(
          `Congratulations!\nYou have successfully minted ${mintAmount} ${CONFIG.NFT_NAME}.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
        dispatch(refreshAccount());
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    const max = maxMintAmount-balance;
    if (newMintAmount > max) {
      newMintAmount = max;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const isWalletConnected = 
    blockchain.account !== "" &&
    blockchain.smartContract !== null;

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={'center'}
        style={{
          padding: '1.3em',
          backgroundColor: 'var(--primary)',
        }}
        image={'/assets/images/bkg.jpg'}
      >
        <StyledLogo alt={"logo"} src={"/assets/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1}>
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/assets/images/nft.gif"} />
          </s.Container>
          <s.SpacerLarge /> */}
          <s.Container
            flex={2}
            image={"/assets/images/minitng-area.png"}
            jc={"center"}
            ai={"center"}
            style={{
              alignSelf: 'center',
              margin: '0 auto',
              opacity: '0.8',
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: `${isWalletConnected ? '50px' : '34px'}`,
                fontWeight: "bold",
                color: "var(--accent-text)",
                lineHeight: "1"
              }}
            >
              {/* {isWalletConnected ? `${data.totalSupply} / ${CONFIG.MAX_SUPPLY}` : 'Connect Your Wallet'} */}
              {isWalletConnected ? `Mint your pirate` : 'Connect Your Wallet'}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--accent-text)",
              }}
            >
              {isWalletConnected ?
                `Your Wallet Address is: ${blockchain.account ? truncateWallet(blockchain.account, 15): ''}`
                : '(We support only Metamask at the moment)'
              }
            </s.TextDescription>

            { Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ 
                    textAlign: "center",
                    color: "var(--accent-text)",
                    lineHeight: '1',
                    marginTop: '1em'
                  }}
                >
                  The Crypto Pirate Ghosts cost is free<br/>and by invitation only.
                </s.TextTitle>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)"
                  }}
                >
                  (Gas fees not included)
                </s.TextDescription>
                {!isWalletConnected ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: `${blockchain.errorMsg === "" ? 'var(--accent-text)' : 'var(--secondary)'}`,
                        lineHeight: 1,
                        marginTop: '1em',
                        maxWidth: '19em',
                        whiteSpace: 'pre-line'
                      }}
                    >
                      {blockchain.errorMsg === "" ?
                        `Be sure to select ${CONFIG.NETWORK.NAME} on Metamask.`
                        : blockchain.errorMsg
                      }
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                        fontSize: `${claimingNft ? '30px' : '19px'}`,
                        fontWeight: `${claimingNft ? 'bold' : 'inherit'}`,
                        maxWidth: '16em',
                        lineHeight:'1.3',
                        marginTop: '0.5em',
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    { claimingNft ?
                      <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          textAlign: 'center',
                          alignItems: 'center',
                          marginTop: '0.5em'
                        }}>
                        <StyledImg
                          alt={"example"}
                          src={"/assets/images/nft.gif"}
                          style={{
                            transform: "scaleX(-1)",
                            marginRight: '1em'
                          }}
                        />
                        <s.TextTitle
                        style={{ 
                          textAlign: "center",
                          color: "var(--accent-text)",
                          lineHeight: '1',
                          fontWeight: 'bold',
                          margin: '2em 0'
                        }}
                      >
                        Minting Your Pirates...
                      </s.TextTitle>
                      </div>
                      : (maxMintAmount-balance) > 0 ?
                        <div style={{
                              display:'flex',
                              flexDirection: 'row',
                              alignSelf: 'center',
                              margin: '0 7em'
                        }}>
                          <div style={{display:'flex', flexDirection: 'column'}}>
                            <s.Container ai={"center"} jc={"center"} fd={"row"} style={{marginTop: '1em'}}>
                              <StyledRoundButton
                                style={{ lineHeight: 0.4 }}
                                // disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                -
                              </StyledRoundButton>
                              <s.SpacerMedium />
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {mintAmount}/{maxMintAmount-balance}
                              </s.TextDescription>
                              <s.SpacerMedium />
                              <StyledRoundButton
                                // disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                +
                              </StyledRoundButton>
                            </s.Container>
                            <s.Container ai={"center"} jc={"center"} fd={"row"}>
                              <StyledButton
                                // disabled={claimingNft ? 1 : 0}
                                style={{margin: '1em 0' }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                                Mint
                              </StyledButton>
                            </s.Container>
                          </div>
                        </div>
                      : <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--secondary)",
                            fontSize: '24px',
                            fontWeight: 'bold',
                            maxWidth: '16em',
                            lineHeight:'1.3'
                          }}
                        >
                          You have reached the maximum minting amount.
                        </s.TextDescription>
                    }
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          {/* <s.SpacerLarge /> */}
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/assets/images/nft.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
        </ResponsiveWrapper>

      </s.Container>
    </s.Screen>
  );
}

export default App;
