// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";
import {BLOCKCHAIN_ACTION} from "./blockchainReducer";
import { WALLETS } from "../../constants";

const connectRequest = () => {
  return {
    type: BLOCKCHAIN_ACTION.CONNECTION_REQUEST,
  };
};

const connectSuccess = (payload) => {
  return {
    type: BLOCKCHAIN_ACTION.CONNECTION_SUCCESS,
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: BLOCKCHAIN_ACTION.CONNECTION_FAILED,
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: BLOCKCHAIN_ACTION.UPDATE_ACCOUNT,
    payload: payload,
  };
};

const refreshAccountRequest = (payload) => {
  return {
    type: BLOCKCHAIN_ACTION.REFRESH_ACCOUNT,
    payload: payload,
  };
};

const isAccountAllowedInWhiteList = async (smartContract, account) => {
  const isOnlyWhiteListed = await smartContract.methods
    .onlyWhiteListed()
    .call();
  // console.log(`isOnlyWhiteListed: ${isOnlyWhiteListed}`);

  // If the contract allows only whitelisters...
  if (isOnlyWhiteListed === true) {
    const isInWhiteList = await smartContract.methods
      .isWhiteListed(account)
      .call();

    // ...and the current user is not among them, it throw an error.
    if (!isInWhiteList) {
      return false;
    }
  }
  return true;
}

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();

    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();

    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID) {
          const smartContract = new Web3EthContract(
            abi,
            CONFIG.CONTRACT_ADDRESS
          );
          // console.log(`account: ${JSON.stringify(accounts)}`);
          const account = accounts[0];
          const walletIndex = Object.keys(WALLETS).findIndex(
            (currentAccount) => currentAccount.toLowerCase() === account
          );
          // const isAccountAllowed = await isAccountAllowedInWhiteList(smartContract, account);
          // if (!isAccountAllowed && (WALLETS.indexOf(account) < 0)) {
          if ((walletIndex < 0)) {
            dispatch(connectFailed(`Your wallet is not allowed to mint.\nPlease change your wallet or contact the administrators.`));
            return;
          }

          let maxMintAmount;
          const maxAmountAllowed = Object.values(WALLETS)[walletIndex];
          if (maxAmountAllowed >= 0) {
            maxMintAmount = maxAmountAllowed;
          } else {
            const maxMintAmountInContract = await smartContract.methods
              .maxMintAmount()
              .call();
            maxMintAmount = maxMintAmountInContract > 0 ? maxMintAmountInContract : 0;
          }
          // console.log(`maxMintAmount: ${maxMintAmount}`);

          const balance = await smartContract.methods
            .balanceOf(account)
            .call();
          // console.log(`balance: ${balance}`);

          dispatch(
            connectSuccess({
              account,
              smartContract,
              web3,
              maxMintAmount,
              balance
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            if (accounts.length < 1) {
              window.location.reload();
              return;
            }
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          ethereum.on('disconnect', () => {
            window.location.reload();
          });

          // Add listeners end
        } else {
          dispatch(connectFailed(`Please change your network to ${CONFIG.NETWORK.NAME}.`));
        }
      } catch (err) {
        dispatch(connectFailed("Something went wrong. Please try again."));
      }
    } else {
      dispatch(connectFailed("Please install Metamask."));
    }
  };
};

export const refreshAccount = () => {
  return async (dispatch, getState) => {
    const {account, smartContract} = getState().blockchain;

    const isAccountAllowed = await isAccountAllowedInWhiteList(smartContract, account);
    if (!isAccountAllowed) {
      dispatch(connectFailed(`Your wallet is not in the whitelist.\nPlease change your wallet or ask to be added to the whitelist.`));
      return;
    }

    const walletIndex = Object.keys(WALLETS).findIndex(
      (currentAccount) => currentAccount.toLowerCase() === account
    );
    // const isAccountAllowed = await isAccountAllowedInWhiteList(smartContract, account);
    // if (!isAccountAllowed && (WALLETS.indexOf(account) < 0)) {
    if ((walletIndex < 0)) {
      dispatch(connectFailed(`Your wallet is not allowed to mint.\nPlease change your wallet or contact the administrators.`));
      return;
    }

    let maxMintAmount;
    const maxAmountAllowed = Object.values(WALLETS)[walletIndex];
    if (maxAmountAllowed >= 0) {
      maxMintAmount = maxAmountAllowed;
    } else {
      const maxMintAmountInContract = await smartContract.methods
        .maxMintAmount()
        .call();
      maxMintAmount = maxMintAmountInContract > 0 ? maxMintAmountInContract : 0;
    }

    const balance = await smartContract.methods
      .balanceOf(account)
      .call();
    // console.log(`balance: ${balance}`);

    dispatch(refreshAccountRequest({
      maxMintAmount,
      balance
    }));
  }
}

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
    dispatch(refreshAccount());
  };
};
