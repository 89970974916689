// log
import store, {ACTIONS} from "../store";

const fetchDataRequest = () => {
  return {
    type: ACTIONS.CHECK_DATA_REQUEST,
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: ACTIONS.CHECK_DATA_SUCCESS,
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: ACTIONS.CHECK_DATA_FAILED,
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const state = store.getState();
      const totalSupply = await state
        .blockchain.smartContract.methods.totalSupply()
        .call();
      const cost = await state
        .blockchain.smartContract.methods.cost()
        .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost
        })
      );
    } catch (err) {
      // console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

