export const WALLETS = {
    "0x55bBb768a354Dc6B0d13523083F790801A8AFDA5": 1,
    "0x3Cb93A1601642bCA3c09f121EcE68Bd34768E242": 1,
    "0x76c2E108a6060eB6B65611a3f1b8395F4cd4dc97": 1,
    "0x68bAF6CC8910218519b6E8AE685d6e1CbB338A06": 3,
    "0x635eA148b24fA920344fe7F3a3040C04A6C1B1FE ": 1,
    "0x577916bFc93a80c0f8A3aCaBFd928F29446b0761": 1,
    "0xeb6eB4D556fADA7D0380E0171203E229c13756Ef": 5,
    "0x55874145118e28bd7c631223d35d0E3a546675e8": 1,
    "0xf928eEA820E4686283A603f3A003DB573a25E16b": 1,
    "0x4e51d49dACEfFEDbB8e8e3449fbB3d8059431B44": 1,
    "0x23ab87cc84002e97a625d3c538d5309b56edcbea": 2,
    "0x06d920c15b3c61db509b2af48955ec72224c1500 ": 1,
    "0x17185dc2768333f2deC24725926DB1fCCF886B85": 1,
};